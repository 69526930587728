var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material_box" }, [
    _c(
      "div",
      { staticClass: "my_meterial" },
      [
        _c(
          "el-row",
          { staticStyle: { height: "100%" }, attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              {
                staticStyle: { height: "100%", "padding-right": "0" },
                attrs: { span: 16 }
              },
              [
                _c("div", { staticClass: "left_content" }, [
                  _c(
                    "div",
                    { staticClass: "top_search" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入素材名称或关键字" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchMeterial($event)
                            }
                          },
                          model: {
                            value: _vm.searchValue,
                            callback: function($$v) {
                              _vm.searchValue = $$v
                            },
                            expression: "searchValue"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                slot: "prepend",
                                placeholder: "请选择",
                                "popper-append-to-body": false
                              },
                              on: { change: _vm.changeMeterial },
                              slot: "prepend",
                              model: {
                                value: _vm.materialSelect,
                                callback: function($$v) {
                                  _vm.materialSelect = $$v
                                },
                                expression: "materialSelect"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "视频", value: 1 }
                              }),
                              _c("el-option", {
                                attrs: { label: "图片", value: 2 }
                              })
                            ],
                            1
                          ),
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.searchMeterial },
                            slot: "append"
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox",
                        {
                          class: _vm.meterialList.length
                            ? ""
                            : _vm.filterChecked
                            ? ""
                            : "opacity",
                          staticStyle: { "margin-left": "92px" },
                          attrs: {
                            disabled: _vm.meterialList.length
                              ? false
                              : _vm.filterChecked
                              ? false
                              : true
                          },
                          on: { change: _vm.filterCheck },
                          model: {
                            value: _vm.filterChecked,
                            callback: function($$v) {
                              _vm.filterChecked = $$v
                            },
                            expression: "filterChecked"
                          }
                        },
                        [_vm._v("仅显示未标签素材")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.ifLoading,
                          expression: "ifLoading"
                        }
                      ],
                      ref: "middleListContent",
                      staticClass: "middle_list",
                      attrs: {
                        "element-loading-background": "rgba(0, 0, 0, 0.1)",
                        "element-loading-text": "素材加载中..."
                      }
                    },
                    [
                      _vm.materialSelect == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "list img_list",
                              style: _vm.meterialList.length
                                ? "padding-bottom:40px;"
                                : ""
                            },
                            _vm._l(_vm.meterialList, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item img_item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img_outer",
                                      attrs: { id: item.ip_id },
                                      on: {
                                        click: function($event) {
                                          return _vm.checkAndEdit(item.ip_id)
                                        },
                                        mouseenter: function($event) {
                                          $event.stopPropagation()
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          ) {
                                            return null
                                          }
                                          return _vm.imgMouseenter($event)
                                        },
                                        mouseleave: function($event) {
                                          $event.stopPropagation()
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          ) {
                                            return null
                                          }
                                          return _vm.imgMouseleave($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        class: [
                                          "gifNode",
                                          item.gifNode ? "gifNode_show" : ""
                                        ],
                                        attrs: { src: item.gifNode, alt: "" }
                                      }),
                                      item.showImg
                                        ? _c("img", {
                                            staticClass: "imgNode",
                                            attrs: {
                                              src: item.cover_img,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      !item.cover_img
                                        ? _c("i", {
                                            staticClass: "iconfont icon-shipin1"
                                          })
                                        : _vm._e(),
                                      item.ifChoose
                                        ? _c("i", {
                                            staticClass: "choose_material"
                                          })
                                        : _c(
                                            "span",
                                            { staticClass: "video_duration" },
                                            [_vm._v(_vm._s(item.duration))]
                                          ),
                                      _c(
                                        "div",
                                        { staticClass: "hover_effect" },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "iconfont icon-shanchujilu",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.deleteConfirm(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", {
                                            staticClass: "iconfont icon-xiazai",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.videoDownloadConfirm(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", {
                                            staticClass:
                                              "iconfont icon-tianjiazhiguankanliebiao",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.joinWatchList(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", {
                                            class: [
                                              "iconfont",
                                              item.isStar
                                                ? "icon-shoucang"
                                                : "icon-weishoucang"
                                            ],
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.ifStar(item)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      directives: [
                                        {
                                          name: "delTabIndex",
                                          rawName: "v-delTabIndex"
                                        }
                                      ],
                                      attrs: {
                                        effect: "dark",
                                        content: item.name,
                                        placement:
                                          !_vm.filterNum(index + 1) &&
                                          item.name.length > 18
                                            ? "bottom-end"
                                            : "bottom-start",
                                        "popper-class": "my_material_tooltip"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "one_line_ellipsis title",
                                          on: {
                                            click: function($event) {
                                              return _vm.goMaterialDetail(
                                                item.ip_id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.materialSelect == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "list img_list",
                              style: _vm.meterialList.length
                                ? "padding-bottom:40px;"
                                : ""
                            },
                            _vm._l(_vm.meterialList, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item img_item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img_outer",
                                      on: {
                                        click: function($event) {
                                          return _vm.checkAndEdit(item.ip_id)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: item.cover, alt: "" }
                                      }),
                                      item.ifChoose
                                        ? _c("i", {
                                            staticClass: "choose_material"
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "hover_effect" },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "iconfont icon-shanchujilu",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.deleteConfirm(
                                                  item.ip_id
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", {
                                            staticClass: "iconfont icon-xiazai",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.downloadImg(
                                                  item.url,
                                                  item.name
                                                )
                                              }
                                            }
                                          }),
                                          _c("span", {
                                            class: [
                                              "iconfont",
                                              item.isStar
                                                ? "icon-shoucang"
                                                : "icon-weishoucang"
                                            ],
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.ifStar(item)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      directives: [
                                        {
                                          name: "delTabIndex",
                                          rawName: "v-delTabIndex"
                                        }
                                      ],
                                      attrs: {
                                        effect: "dark",
                                        content: item.name,
                                        placement:
                                          !_vm.filterNum(index + 1) &&
                                          item.name.length > 18
                                            ? "bottom-end"
                                            : "bottom-start",
                                        "popper-class": "my_material_tooltip"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "one_line_ellipsis title",
                                          on: {
                                            click: function($event) {
                                              return _vm.goMaterialDetail(
                                                item.ip_id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      !_vm.ifSearch &&
                      !_vm.meterialList.length &&
                      !_vm.ifLoading
                        ? _c("noResult", {
                            attrs: {
                              iconUrl: "icon-meiyoujieguo",
                              iconSty: "font-size:50px",
                              textSty: "font-size:14px",
                              noResultText: "没有符合条件的素材"
                            }
                          })
                        : _vm._e(),
                      _vm.ifSearch && !_vm.meterialList.length && !_vm.ifLoading
                        ? _c("noResult", {
                            attrs: {
                              iconUrl: "icon-meiyousucai",
                              iconSty: "font-size:50px",
                              textSty: "font-size:14px",
                              noResultText: "暂时没有素材"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bottom_des" }, [
                    _vm._v(" 共" + _vm._s(_vm.totalCount) + "个素材 ")
                  ])
                ])
              ]
            ),
            _c(
              "el-col",
              { staticStyle: { padding: "0" }, attrs: { span: 6, offset: 2 } },
              [
                _c("div", { staticClass: "right_detail" }, [
                  _c("div", { staticClass: "title" }, [_vm._v(" 编辑信息 ")]),
                  _c(
                    "div",
                    { staticClass: "edit_detail" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("名称")
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c("el-input", {
                                class: _vm.meterialList.length ? "" : "opacity",
                                attrs: {
                                  placeholder: "",
                                  size: "small",
                                  disabled: _vm.meterialList.length
                                    ? false
                                    : true,
                                  maxlength: 40
                                },
                                model: {
                                  value: _vm.materialName,
                                  callback: function($$v) {
                                    _vm.materialName = $$v
                                  },
                                  expression: "materialName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("简介")
                          ]),
                          _c(
                            "el-col",
                            { staticClass: "intro_sty", attrs: { span: 20 } },
                            [
                              _c("el-input", {
                                class: [
                                  "text_area_sty",
                                  _vm.meterialList.length ? "" : "opacity"
                                ],
                                attrs: {
                                  placeholder: "简介最多输入200个字哦~",
                                  type: "textarea",
                                  rows: 3,
                                  maxlength: 200,
                                  disabled: _vm.meterialList.length
                                    ? false
                                    : true
                                },
                                model: {
                                  value: _vm.materialIntro,
                                  callback: function($$v) {
                                    _vm.materialIntro = $$v
                                  },
                                  expression: "materialIntro"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("标签")
                          ]),
                          _c("el-col", { attrs: { span: 20 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "tag_box",
                                class: _vm.meterialList.length ? "" : "opacity",
                                style: _vm.meterialList.length
                                  ? ""
                                  : "cursor:not-allowed;cursor:no-drop;",
                                attrs: {
                                  disabled: _vm.meterialList.length
                                    ? false
                                    : true
                                },
                                on: {
                                  click: function($event) {
                                    _vm.meterialList.length
                                      ? _vm.handleEditDialog()
                                      : ""
                                  }
                                }
                              },
                              [
                                !_vm.tagList.length
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "rgba(255,255,255,0.8)"
                                        }
                                      },
                                      [_vm._v("点击添加标签")]
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.tagList, function(tag) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag.tag_id,
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false
                                      },
                                      on: {
                                        close: function($event) {
                                          return _vm.handleClose(tag.tag_id)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(tag.tag_name) + " ")]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("关键词")
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c("el-input", {
                                staticClass: "text_area_sty",
                                class: _vm.meterialList.length ? "" : "opacity",
                                attrs: {
                                  placeholder: "多个关键词用“、”隔开",
                                  type: "textarea",
                                  rows: 5,
                                  disabled: _vm.meterialList.length
                                    ? false
                                    : true
                                },
                                model: {
                                  value: _vm.materialKeywords,
                                  callback: function($$v) {
                                    _vm.materialKeywords = $$v
                                  },
                                  expression: "materialKeywords"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("商用授权")
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c("el-input", {
                                staticClass: "text_area_sty",
                                class: _vm.meterialList.length ? "" : "opacity",
                                attrs: {
                                  placeholder: "",
                                  type: "textarea",
                                  rows: 5,
                                  disabled: _vm.meterialList.length
                                    ? false
                                    : true
                                },
                                model: {
                                  value: _vm.commercialLicenses,
                                  callback: function($$v) {
                                    _vm.commercialLicenses = $$v
                                  },
                                  expression: "commercialLicenses"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("拍摄者")
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c("el-input", {
                                class: _vm.meterialList.length ? "" : "opacity",
                                attrs: {
                                  placeholder: "多个拍摄者用“、”隔开",
                                  size: "small",
                                  disabled: _vm.meterialList.length
                                    ? false
                                    : true
                                },
                                model: {
                                  value: _vm.photographer,
                                  callback: function($$v) {
                                    _vm.photographer = $$v
                                  },
                                  expression: "photographer"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "2.2vh" },
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _vm._v("谁可以看")
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "ifsee_select",
                                  class: _vm.meterialList.length
                                    ? ""
                                    : "opacity",
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "mini",
                                    "popper-append-to-body": false,
                                    disabled: _vm.meterialList.length
                                      ? false
                                      : true
                                  },
                                  on: { change: _vm.setPermissions },
                                  model: {
                                    value: _vm.viewTypeValue,
                                    callback: function($$v) {
                                      _vm.viewTypeValue = $$v
                                    },
                                    expression: "viewTypeValue"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "allow_change",
                          attrs: { type: "flex" }
                        },
                        [
                          _c("el-col", { attrs: { span: 4 } }),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  class: _vm.meterialList.length
                                    ? ""
                                    : "opacity",
                                  attrs: {
                                    disabled: _vm.meterialList.length
                                      ? false
                                      : true
                                  },
                                  model: {
                                    value: _vm.allowChange,
                                    callback: function($$v) {
                                      _vm.allowChange = $$v
                                    },
                                    expression: "allowChange"
                                  }
                                },
                                [_vm._v("允许其他用户修改标签")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { type: "flex" } },
                        [
                          _c("el-col", { attrs: { span: 4 } }),
                          _c(
                            "el-col",
                            {
                              staticClass: "operation_btn",
                              staticStyle: { "text-align": "right" },
                              attrs: { span: 20 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  class: _vm.meterialList.length
                                    ? ""
                                    : "opacity",
                                  attrs: {
                                    size: "small",
                                    disabled: _vm.meterialList.length
                                      ? false
                                      : true
                                  },
                                  on: { click: _vm.clearAll }
                                },
                                [_vm._v("清 空")]
                              ),
                              _c(
                                "el-button",
                                {
                                  class: _vm.meterialList.length
                                    ? ""
                                    : "opacity",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    size: "small",
                                    disabled: _vm.meterialList.length
                                      ? false
                                      : true
                                  },
                                  on: { click: _vm.editMeterialDetail }
                                },
                                [_vm._v("保 存")]
                              ),
                              _vm.saveMeterial
                                ? _c("div", { staticClass: "success_info" }, [
                                    _vm._v("素材信息保存成功")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "下载",
              visible: _vm.downloadDialog,
              width: "461px",
              top: "30vh",
              "custom-class": "material-dialog",
              "show-close": true,
              "before-close": _vm.downloadClose
            },
            on: {
              "update:visible": function($event) {
                _vm.downloadDialog = $event
              }
            }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  display: "block",
                  "margin-bottom": "21px",
                  color: "rgba(255,255,255,0.8)"
                }
              },
              [_vm._v("分辨率：")]
            ),
            _c(
              "div",
              { staticClass: "radio_check" },
              [
                _vm.downloadResolution.length == 1
                  ? _c(
                      "el-radio",
                      {
                        staticClass: "disable_radio",
                        attrs: { label: "标清" }
                      },
                      [_vm._v("标清")]
                    )
                  : _vm._e(),
                _vm._l(_vm.downloadResolution, function(item, i) {
                  return [
                    _c(
                      "el-radio",
                      {
                        key: i,
                        attrs: { label: item.resolution },
                        model: {
                          value: _vm.system,
                          callback: function($$v) {
                            _vm.system = $$v
                          },
                          expression: "system"
                        }
                      },
                      [_vm._v(_vm._s(item.resolution))]
                    )
                  ]
                })
              ],
              2
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.cnfirmDownloadMeterial }
                  },
                  [_vm._v("确 定")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "30px" },
                    on: {
                      click: function($event) {
                        _vm.downloadDialog = false
                      }
                    }
                  },
                  [_vm._v("取 消")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.viewTypeValue == "3" ? "部分可见" : "不给谁看",
              visible: _vm.setPermissionsDialog,
              width: "382px",
              top: "30vh",
              "close-on-click-modal": false,
              "close-on-press-escape": false,
              "custom-class": "permission-dialog",
              "show-close": true,
              "before-close": _vm.setPermissionsClose
            },
            on: {
              "update:visible": function($event) {
                _vm.setPermissionsDialog = $event
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "search_tree" },
              [
                _c("el-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  attrs: {
                    placeholder: "输入关键字",
                    "suffix-icon": "el-icon-search",
                    size: "mini"
                  },
                  model: {
                    value: _vm.filterText,
                    callback: function($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText"
                  }
                }),
                _c("el-tree", {
                  ref: "tree",
                  staticClass: "filter-tree",
                  attrs: {
                    data: _vm.dataSee,
                    "show-checkbox": "",
                    "default-expand-all": "",
                    "default-checked-keys": [5],
                    "node-key": "id",
                    "highlight-current": "",
                    props: _vm.defaultProps,
                    "filter-node-method": _vm.filterNode
                  },
                  on: { check: _vm.checkTree }
                })
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.ensurePermission }
                  },
                  [_vm._v("确 定")]
                ),
                _c("el-button", { on: { click: _vm.cancelPermission } }, [
                  _vm._v("取 消")
                ])
              ],
              1
            )
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "标签编辑",
              visible: _vm.showEditDialog,
              width: "461px",
              top: "30vh",
              "custom-class": "edit-dialog",
              "show-close": true,
              "before-close": _vm.closeEditDialog
            },
            on: {
              "update:visible": function($event) {
                _vm.showEditDialog = $event
              }
            }
          },
          [
            _c("div", { staticClass: "search_tag" }, [
              _c(
                "div",
                { staticClass: "top_search" },
                [
                  _c("el-autocomplete", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    ref: "autoTag",
                    attrs: {
                      "popper-class": "search-autocpmplete",
                      "trigger-on-focus": false,
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: "请输入标签",
                      size: "small",
                      "popper-append-to-body": true,
                      debounce: 10
                    },
                    on: { select: _vm.handleSelect, focus: _vm.focusEdit },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("div", { staticClass: "search_tag" }, [
                              _vm._v(_vm._s(item.value))
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.tagValue,
                      callback: function($$v) {
                        _vm.tagValue = $$v
                      },
                      expression: "tagValue"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "edit_btn", on: { click: _vm.addTag } },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "提 示",
              visible: _vm.warningDialog,
              width: "400px",
              top: "30vh",
              "custom-class": "permission-dialog",
              "show-close": true,
              "before-close": _vm.warningClose
            },
            on: {
              "update:visible": function($event) {
                _vm.warningDialog = $event
              }
            }
          },
          [
            _c("div", { staticClass: "warning_content" }, [
              _c("div", [_vm._v(" 您确定要把素材放入回收站吗？ ")])
            ]),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.deleteMeterial }
                  },
                  [_vm._v("是")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.warningDialog = false
                      }
                    }
                  },
                  [_vm._v("否")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "提 示",
              visible: _vm.saveMeterialDialog,
              width: "400px",
              top: "30vh",
              "custom-class": "permission-dialog",
              "show-close": false,
              "close-on-click-modal": false,
              "close-on-press-escape": false
            },
            on: {
              "update:visible": function($event) {
                _vm.saveMeterialDialog = $event
              }
            }
          },
          [
            _c("div", { staticClass: "warning_content" }, [
              _c("div", [_vm._v(" 已编辑素材信息未保存，是否保存？ ")])
            ]),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.saveMeterialInfo }
                  },
                  [_vm._v("是")]
                ),
                _c("el-button", { on: { click: _vm.donNotSaveEdit } }, [
                  _vm._v("否")
                ])
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }